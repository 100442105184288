import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { FilterButton, MoreBar } from '@revolut/ui-kit'

import { navigateTo } from '@src/actions/RouterActions'
import { payCycleReportsTableRequests } from '@src/api/payroll'
import { useGetSelectors } from '@src/api/selectors'
import FilterButtonCheckboxSelect from '@src/components/FilterButtonCheckboxSelect/FilterButtonCheckboxSelect'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import Stat from '@src/components/Stat/Stat'
import { useTable } from '@src/components/Table/hooks'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import Table from '@src/components/TableV2/Table'
import { LOCAL_STORAGE, selectorKeys } from '@src/constants/api'
import {
  getPayCycleCountryColumn,
  payCycleEndDateColumn,
  payCycleEntityColumn,
  payCycleHeadcountColumn,
  payCycleIssuesColumn,
  payCyclePayDayColumn,
  payCycleStartDateColumn,
  payCycleStatusColumn,
  payCycleSubmissionDateColumn,
  payCycleTotalContributionColumn,
  payCycleTotalDeductionsColumn,
  payCycleTotalGrossColumn,
  payCycleTotalNetColumn,
} from '@src/constants/columns/payCycles'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { FilterByInterface, RowInterface } from '@src/interfaces/data'
import { PayCycleInterface } from '@src/interfaces/payroll'
import { OptionInterface } from '@src/interfaces/selectors'
import { selectPermissions, selectUser } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'

import { AddNewPayCycleSidebar } from './AddNewPayCycleSidebar'
import { useIsNewTable } from '@src/components/TableV2/hooks'

const getRow: (isNewTable: boolean) => RowInterface<PayCycleInterface> = isNewTable => ({
  linkToForm: data =>
    navigateTo(
      pathToUrl(ROUTES.APPS.PAYROLL.PAY_CYCLE.REPORTS, {
        id: data.id,
      }),
    ),
  cells: [
    {
      ...getPayCycleCountryColumn(isNewTable),
      width: 150,
    },
    {
      ...payCycleHeadcountColumn,
      width: 100,
    },
    {
      ...payCycleStartDateColumn,
      width: 130,
    },
    {
      ...payCycleEndDateColumn,
      width: 130,
    },
    {
      ...payCycleEntityColumn,
      width: 150,
    },
    {
      ...payCycleSubmissionDateColumn,
      width: 100,
    },
    {
      ...payCyclePayDayColumn,
      width: 100,
    },
    {
      ...payCycleIssuesColumn,
      width: 140,
    },
    {
      ...payCycleStatusColumn,
      width: 100,
    },
  ],
})

export const PayCyclesTable = () => {
  const [selectedCountryFilter, setSelectedCountryFilter] = useState<OptionInterface[]>(
    [],
  )
  const [selectedEntityFilter, setSelectedEntityFilter] = useState<OptionInterface[]>([])
  const [isAddNewSidebarOpen, setIsAddNewSidebarOpen] = useState(false)

  const { data: countries } = useGetSelectors(selectorKeys.countries)
  const { data: entities } = useGetSelectors(selectorKeys.entity)

  const [ownedByMe, setOwnedByMe] = useLocalStorage(
    LOCAL_STORAGE.PAYCYCLE_OWNED_BY_ME,
    false,
  )
  const [withIssues, setWithIssues] = useLocalStorage(
    LOCAL_STORAGE.PAYCYCLE_WITH_ISSUES,
    false,
  )

  const isCommercial = useIsCommercial()
  const user = useSelector(selectUser)
  const permissions = useSelector(selectPermissions)
  const canAddPayCycle = permissions.includes(PermissionTypes.AddPayCycle)
  const canViewPayrollPreferences = permissions.includes(
    PermissionTypes.ViewPayrollPreferences,
  )

  const getOwnedByMeFilter = (enabled: boolean): FilterByInterface => ({
    columnName: 'owner__id',
    filters: enabled ? [{ name: user.display_name, id: user.id }] : [],
    nonResettable: true,
  })

  const getWithIssuesFilter = (enabled: boolean): FilterByInterface => ({
    columnName: 'issue_count',
    filters: enabled
      ? [
          { id: '1', name: '1' },
          { id: '', name: '' }, // passing empty values here to simulate range "> 1"
        ]
      : [],
    nonResettable: true,
  })

  const getInitialFilters = () => {
    const initialFilters: FilterByInterface[] = [
      {
        columnName: 'current',
        filters: [{ id: 'true', name: 'true' }],
        nonResettable: true,
      },
    ]
    if (ownedByMe) {
      initialFilters.push(getOwnedByMeFilter(true))
    }
    return initialFilters
  }

  const table = useTable(payCycleReportsTableRequests, getInitialFilters())

  const toggleOwnedByMe = () => {
    setOwnedByMe(prev => {
      const newOwnedByMe = !prev
      table.onFilterChange(getOwnedByMeFilter(newOwnedByMe))
      return newOwnedByMe
    })
  }

  const toggleWithIssues = () => {
    setWithIssues(prev => {
      const newWithIssues = !prev
      table.onFilterChange(getWithIssuesFilter(newWithIssues))
      return newWithIssues
    })
  }

  const selectCountries = (selection: OptionInterface[] | undefined) => {
    setSelectedCountryFilter(selection || [])
    table.onFilterChange({
      filters: selection || [],
      columnName: 'pay_group__country__id',
    })
  }

  const selectEntities = (selection: OptionInterface[] | undefined) => {
    setSelectedEntityFilter(selection || [])
    table.onFilterChange({
      filters: selection || [],
      columnName: 'pay_group__company_entity__id',
    })
  }

  const isNewTable = useIsNewTable()

  return (
    <>
      <Table.Widget>
        <Table.Widget.Info>
          <Stat
            label="Total"
            val={table.loading ? undefined : table.stats?.total_pay_cycles}
          />
          <Stat
            label="Open cycles"
            val={table.loading ? undefined : table.stats?.open_pay_cycles}
          />
        </Table.Widget.Info>
        <Table.Widget.Filters>
          <FilterButton onClick={toggleOwnedByMe} active={ownedByMe}>
            Owned by me
          </FilterButton>
          <FilterButton onClick={toggleWithIssues} active={withIssues}>
            With issues
          </FilterButton>
          <FilterButtonCheckboxSelect
            label="Country"
            searchable
            width={300}
            options={countries || []}
            value={selectedCountryFilter}
            onChange={selectCountries}
          />
          <FilterButtonCheckboxSelect
            label="Entity"
            searchable
            width={300}
            options={entities || []}
            value={selectedEntityFilter}
            onChange={selectEntities}
          />
        </Table.Widget.Filters>
        <Table.Widget.Actions>
          <Table.Widget.MoreBar>
            {canAddPayCycle && (
              <MoreBar.Action useIcon="Plus" onClick={() => setIsAddNewSidebarOpen(true)}>
                Add new
              </MoreBar.Action>
            )}
            <MoreBar.Action
              to={pathToUrl(ROUTES.APPS.PAYROLL.PAYMENTS_TABLE)}
              use={InternalLink}
              useIcon="Pencil"
            >
              Manage payments
            </MoreBar.Action>
            {canViewPayrollPreferences && (
              <MoreBar.Action
                to={pathToUrl(ROUTES.SETTINGS.PAYROLL.GENERAL)}
                use={InternalLink}
                useIcon="Gear"
              >
                Payroll settings
              </MoreBar.Action>
            )}
            <Table.ColumnsSettingsButton />
          </Table.Widget.MoreBar>
        </Table.Widget.Actions>
        <Table.Widget.Table>
          <AdjustableTable
            name={TableNames.PayCycles}
            dataType="paycycle"
            row={getRow(isNewTable)}
            useWindowScroll
            tableSettings={
              isNewTable
                ? {
                    visible: [],
                    hidden: [
                      payCycleEntityColumn.title,
                      payCycleSubmissionDateColumn.title,
                    ],
                  }
                : undefined
            }
            hiddenCells={{
              [payCycleTotalGrossColumn.idPoint]: isCommercial,
              [payCycleTotalContributionColumn.idPoint]: isCommercial,
              [payCycleTotalDeductionsColumn.idPoint]: isCommercial,
              [payCycleTotalNetColumn.idPoint]: isCommercial,
            }}
            {...table}
          />
        </Table.Widget.Table>
      </Table.Widget>
      {canAddPayCycle && (
        <AddNewPayCycleSidebar
          isOpen={isAddNewSidebarOpen}
          onClose={() => setIsAddNewSidebarOpen(false)}
          onSuccess={() => {
            setIsAddNewSidebarOpen(false)
            table.refresh()
          }}
        />
      )}
    </>
  )
}
