import React, { useMemo } from 'react'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable, useIsNewTable } from '@components/TableV2/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { FilterButton, MoreBar } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'
import Stat from '@components/Stat/Stat'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions, selectUser } from '@src/store/auth/selectors'
import {
  departmentCommunicationColumn,
  nipsColumn,
} from '@src/constants/columns/department'
import { ownerNameColumn } from '@src/constants/columns/employee'
import { Statuses } from '@src/interfaces'
import {
  functionBudgetColumn,
  functionGenericColumn,
  functionHeadcountColumn,
  functionLinkColumn,
  functionRequisitionsColumn,
  functionRoadmapColumn,
  functionRoleColumn,
} from '@src/constants/columns/function'
import { functionsRequests } from '@src/api/functions'
import { FunctionInterface, TalentStatsInterface } from '@src/interfaces/functions'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { LOCAL_STORAGE } from '@src/constants/api'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import { useGetRoadmapSettings } from '@src/api/settings'
import { useFavouritesFilter } from '@src/features/FavouritesFilter/useFavouritesFilter'
import { withFavourites } from '@src/features/FavouritesFilter/withFavourites'
import { useGetSlackPreferences } from '@src/api/integrations'
import { SettingsButton } from '@src/features/SettingsButtons/SettingsButton/SettingsButton'
import { settingsConfig } from '@src/pages/Settings/SettingsLandingPage/constants'
import Table from '@components/TableV2/Table'
import { StatNavigation } from '@components/StatNavigation/StatNavigation'
import { useGetRolesStats } from '@src/api/roles'
import { rolesConfig } from '@src/pages/Organisation/OrganisationSubTabs/common'

const FunctionsTable = () => {
  const isNewTable = useIsNewTable()
  const user = useSelector(selectUser)
  const [showMyFunctions, setShowMyFunctions] = useLocalStorage(
    LOCAL_STORAGE.SHOW_MY_FUNCTIONS,
    false,
  )
  const [showArchivedFunctions, setShowArchivedFunctions] = useLocalStorage(
    LOCAL_STORAGE.SHOW_ARCHIVED_FUNCTIONS,
    false,
  )

  const { data: roadmapSettings } = useGetRoadmapSettings()
  const { data: slackSettings } = useGetSlackPreferences()

  const slackEnabled = !!slackSettings?.enabled

  const row: RowInterface<FunctionInterface> = {
    linkToForm: ({ id }) => {
      if (id) {
        navigateTo(pathToUrl(ROUTES.FORMS.FUNCTION.SUMMARY, { id }))
      } else {
        navigateTo(pathToUrl(ROUTES.FORMS.FUNCTION.SETTINGS, { id }))
      }
    },
    cells: [
      {
        ...functionGenericColumn,
        width: 260,
      },
      {
        ...ownerNameColumn,
        width: 260,
      },
      {
        ...functionHeadcountColumn,
        width: 100,
      },
      {
        ...functionRequisitionsColumn,
        width: 100,
      },
      {
        ...nipsColumn,
        width: 100,
      },
      {
        ...functionRoleColumn,
        width: 100,
      },
      {
        ...functionBudgetColumn,
        width: 100,
      },
      slackEnabled
        ? {
            ...departmentCommunicationColumn,
            width: 100,
          }
        : null,
      {
        ...functionLinkColumn,
        width: 100,
      },
    ].filter(Boolean),
  }

  const { FavouritesFilter, initialFilter } = useFavouritesFilter('function')

  const getFilterByOwner = (setFilter: boolean) => ({
    filters: setFilter ? [{ name: user.display_name, id: user.id }] : [],
    columnName: 'owner__id',
    nonResettable: true,
  })

  const getFilterByStatus = (archived: boolean) => ({
    filters: archived
      ? [{ name: Statuses.archived, id: Statuses.archived }]
      : [{ name: Statuses.active, id: Statuses.active }],
    columnName: 'status',
  })

  const getInitialFilter = () => {
    const filter = []

    filter.push(getFilterByStatus(showArchivedFunctions))
    filter.push(getFilterByOwner(showMyFunctions))

    if (initialFilter) {
      filter.push(initialFilter)
    }

    return filter
  }

  const filterBy = getInitialFilter()
  const sortBy = [
    {
      sortBy: 'name',
      direction: SORT_DIRECTION.DESC,
    },
  ]
  const table = useTable<FunctionInterface, TalentStatsInterface>(
    functionsRequests,
    filterBy,
    sortBy,
  )
  const permissions = useSelector(selectPermissions)

  const canAdd = permissions.includes(PermissionTypes.AddFunction)

  const onToggleMyFunctions = () => {
    setShowMyFunctions(!showMyFunctions)
    table.onFilterChange(getFilterByOwner(!showMyFunctions))
  }

  const onToggleArchivedFunctions = () => {
    setShowArchivedFunctions(!showArchivedFunctions)
    table.onFilterChange(getFilterByStatus(!showArchivedFunctions))
  }

  const tableSettings = useMemo(
    () =>
      isNewTable
        ? {
            visible: [],
            hidden: [departmentCommunicationColumn.title],
          }
        : undefined,
    [isNewTable],
  )

  return (
    <Table.Widget>
      <Table.Widget.Info>
        {isNewTable ? (
          <StatNavigation api={useGetRolesStats} config={rolesConfig} />
        ) : (
          <Stat label="Total" val={table?.loading ? undefined : table?.count} />
        )}
      </Table.Widget.Info>
      <Table.Widget.Search>
        <Table.Search
          placeholder="Search by function name"
          onFilter={table.onFilterChange}
        />
      </Table.Widget.Search>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar maxCount={3}>
          {canAdd && (
            <MoreBar.Action
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.FUNCTION.SETTINGS)}
              useIcon={Plus}
            >
              Add Function
            </MoreBar.Action>
          )}
          <SettingsButton
            path={ROUTES.SETTINGS.ROLES.GENERAL}
            canView={settingsConfig.roles.canView}
          />
          <Table.ColumnsSettingsButton />
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Filters>
        <FilterButton onClick={onToggleMyFunctions} active={showMyFunctions}>
          My functions
        </FilterButton>
        <FilterButton onClick={onToggleArchivedFunctions} active={showArchivedFunctions}>
          Archived functions
        </FilterButton>
        <FavouritesFilter table={table} />
      </Table.Widget.Filters>
      <Table.Widget.Table>
        <AdjustableTable<FunctionInterface, TalentStatsInterface>
          name={TableNames.Functions}
          useWindowScroll
          dataType="Function"
          row={row}
          {...table}
          noDataMessage="Functions will appear here."
          hideCount={!!isNewTable}
          hiddenCells={{
            [functionRoadmapColumn.idPoint]: !roadmapSettings?.enabled,
          }}
          tableSettings={tableSettings}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export default withFavourites(FunctionsTable)
