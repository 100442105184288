import Form from '@src/features/Form/Form'
import { jiraPreferencesRequests } from '@src/api/integrations'
import React from 'react'
import { JiraForm } from '@src/pages/Hub/Integrations/Jira/Jira'
import { serverNameValidators } from '@src/pages/Forms/GoalForm/Form/GoalMetricForm/constants'
import { UpdateTypes } from '@src/interfaces/kpis'

const JiraIntegration = () => {
  return (
    <Form
      api={jiraPreferencesRequests}
      forceParams={{ id: '1' }}
      validator={serverNameValidators[UpdateTypes.roadmap]}
    >
      <JiraForm />
    </Form>
  )
}

export default JiraIntegration
