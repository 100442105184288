import React, { useState } from 'react'
import { useQueryClient } from 'react-query'
import {
  ActionButton,
  Avatar,
  Box,
  InputGroup,
  Item,
  Link,
  Subheader,
  Text,
  TransitionCollapse,
  Token,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { LogoGoogle, LogoMicrosoft } from '@revolut/icons'
import { useGetEmployeePersonalSettings } from '@src/api/employees'
import {
  askGoogleEmailPermissions,
  askMicrosoftEmailPermissions,
} from '@src/api/officeSuiteProvider'
import { unlinkContactHrEmailAccount } from '@src/api/settings'
import { API, selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { IdAndName, Statuses } from '@src/interfaces'
import { EmployeeOnboardingSettingsInterface } from '@src/interfaces/settings'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import LapeHTMLEditor from '@src/components/Inputs/LapeFields/LapeHTMLEditor'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import useFetchOptions from '@src/components/Inputs/hooks/useFetchOptions'
import SideBar from '@src/components/SideBar/SideBar'
import { Switch } from '@components/Inputs/Switch/Switch'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import AutoStepper from '@components/Stepper/AutoStepper'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { StartPageView } from '@src/pages/Onboarding/common'
import {
  mapAccountStatusToProps,
  useShowOfficeSuitePermissionsError,
} from '@src/utils/linkedAccounts'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'

interface EmailDomainMessageProps {
  type: 'internal' | 'external'
}

const EmailDomainMessage = ({ type }: EmailDomainMessageProps) => {
  return (
    <>
      This will be the email domain used to generate emails for {type} employees. Email
      domains are configured in{' '}
      <Link href={ROUTES.SETTINGS.EMPLOYEES.PROFILE} target="_blank">
        employee app settings
      </Link>
    </>
  )
}

const EmailIntegration = () => {
  const { data: employeePersonalSettings } = useGetEmployeePersonalSettings()
  const { values } = useLapeContext<EmployeeOnboardingSettingsInterface>()
  const isAccountLinked = !!values.contact_hr_email
  const isEmailIntegrationEnabled = !!employeePersonalSettings?.email_integration_enabled
  const isGoogle = employeePersonalSettings?.office_suite_provider === 'google'

  useShowOfficeSuitePermissionsError()
  const showStatusPopup = useShowStatusPopup()

  const onClickSyncEmails = async () => {
    if (isAccountLinked) {
      try {
        await unlinkContactHrEmailAccount()
        values.contact_hr_email = null
        values.contact_hr_display_name = null
      } catch (e) {
        showStatusPopup({
          title: 'Something went wrong',
          description: getStringMessageFromError(e),
          status: 'error',
        })
      }
    } else if (isGoogle) {
      await askGoogleEmailPermissions('employee_onboarding_settings')
    } else {
      await askMicrosoftEmailPermissions('employee_onboarding_settings')
    }
  }

  if (!employeePersonalSettings?.office_suite_provider) {
    return null
  }

  const { color, statusIcon, statusText } = mapAccountStatusToProps(
    isAccountLinked ? Statuses.linked : Statuses.pending,
  )
  const title = isAccountLinked
    ? values.contact_hr_email
    : isGoogle
    ? 'Gmail'
    : 'Microsoft Email'

  return (
    <VStack gap="s-16">
      {!isEmailIntegrationEnabled && (
        <ActionWidget
          avatarColor={Token.color.grey20}
          title="Please connect an email provider before linking your account"
        />
      )}
      <Item
        disabled={!isEmailIntegrationEnabled}
        onClick={onClickSyncEmails}
        use="button"
      >
        <Item.Avatar>
          <Avatar useIcon={isGoogle ? LogoGoogle : LogoMicrosoft}>
            <Avatar.Badge
              bg={color}
              color={Token.color.background}
              useIcon={statusIcon}
            />
          </Avatar>
        </Item.Avatar>
        <Item.Content>
          <Item.Title>{title}</Item.Title>
          <Item.Description color={color}>{statusText}</Item.Description>
        </Item.Content>
        <Item.Side>
          <Switch
            checked={isAccountLinked}
            disabled={!isEmailIntegrationEnabled}
            onChange={e => {
              e.stopPropagation()
              onClickSyncEmails()
            }}
          />
        </Item.Side>
      </Item>
      {isAccountLinked && (
        <LapeNewInput
          label="Onboarding team display name"
          name="contact_hr_display_name"
          description="This is the name displayed as the sender of the email to employees"
          required
        />
      )}
    </VStack>
  )
}

export const General = () => {
  const queryClient = useQueryClient()
  const { values } = useLapeContext<EmployeeOnboardingSettingsInterface>()
  const [welcomePreviewOpen, setWelcomePreviewOpen] = useState(false)

  const { options: allowedEmailDomains } = useFetchOptions<IdAndName<string>>(
    selectorKeys.allowed_email_domains,
  )

  return (
    <>
      <PageBody>
        <AutoStepper>
          <NewStepperTitle title="General settings" />
          <Widget>
            <LapeNewSwitch
              itemTypeProps={{
                title: 'External employees should also go through onboarding',
                description:
                  'If activated, external employees will undergo the same onboarding process as internal employees',
              }}
              name="enable_external_employees_onboarding"
            />
          </Widget>

          <NewStepperTitle title="Contact settings" />
          <Widget p="s-16">
            <Text variant="emphasis1">
              Which email should new employees contact to reach your HR team?
            </Text>
            <Subheader variant="nested">
              <Subheader.Title>
                This is the email and name that new joiners will see when contacting your
                HR team and also where we can send emails from.
              </Subheader.Title>
            </Subheader>

            <EmailIntegration />
          </Widget>

          <Widget mt="s-16" p="s-16">
            <Text variant="emphasis1">What should the invitation email look like?</Text>
            <Subheader variant="nested">
              <Subheader.Title>
                This is the email that is sent to employees once they are invited to start
                their onboarding. Make sure to customise it and make it your own.
              </Subheader.Title>
            </Subheader>

            <InputGroup>
              <LapeNewInput
                label="Email subject"
                name="invitation_email_subject"
                description="This is the subject of the email new joiners will receive to start their onboarding"
                required
              />
              <LapeHTMLEditor
                height={300}
                name="invitation_email_body"
                message="This is the content of the email new joiners will receive to start their onboarding"
                placeholder="Email text"
                required
              />
            </InputGroup>
          </Widget>

          <NewStepperTitle title="Onboarding Portal" />
          <Widget p="s-16">
            <Text variant="emphasis1">
              How do you want to greet your employees on the onboarding welcome page?
            </Text>
            <Subheader variant="nested">
              <Subheader.Title>
                This will be the first page the employee sees when they land on the
                onboarding portal. We recommend detailing a brief presentation about your
                company and important details employees should know about your onboarding
                process.
              </Subheader.Title>
            </Subheader>

            <InputGroup>
              <ActionButton onClick={() => setWelcomePreviewOpen(true)}>
                Preview welcome page
              </ActionButton>
              <LapeNewInput
                label="Welcome page title"
                name="welcome_page_title"
                description="This will be the title of the welcome page. The first page the employee will see landing on the onboarding page"
                required
              />
              <LapeNewInput
                label="Welcome page video URL"
                name="welcome_page_video_url"
                description="This will be used as the onboarding video for new joiners"
              />
              <LapeHTMLEditor
                height={300}
                name="welcome_page_html"
                message="This will be the content displayed on the welcome page"
                placeholder="Welcome page text"
                required
              />
            </InputGroup>
          </Widget>

          <Widget mt="s-16" p="s-16">
            <Text variant="emphasis1">
              Where should employees be directed after they complete the onboarding
              portal?
            </Text>
            <Subheader variant="nested">
              <Subheader.Title>
                This will be the success page that your new hired employees will see once
                they complete their self-onboarding via the portal.
              </Subheader.Title>
            </Subheader>
            <InputGroup>
              <LapeNewInput
                label="Custom URL"
                name="redirect_url_on_completion"
                required
              />
            </InputGroup>
          </Widget>

          <Widget mt="s-16">
            <Box px="s-16" pt="s-16">
              <Text variant="emphasis1">
                Which extra information should be collected during onboarding?
              </Text>
              <Subheader variant="nested">
                <Subheader.Title>
                  Select below which type of extra information should be managed and
                  collected during onboarding
                </Subheader.Title>
              </Subheader>
            </Box>
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Collect diversity data from new employees',
                description:
                  'We recommend consulting a legal expert before gathering diversity data',
              }}
              name="collect_diversity_information"
            />
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Collect information for tech equipment delivery',
                description:
                  'Collects information from the employee about where to deliver tech equipment',
              }}
              name="collect_equipment_shipment_information"
            />
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Collect employee background screening',
                description:
                  'Enables HR managers to trigger background check during onboarding',
              }}
              name="collect_screening_information"
            />
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Collect and manage information regarding right to work',
                description:
                  'Enables HR managers to manage visas and work authorisations',
              }}
              name="collect_rights_to_work_information"
            />
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Collect the employee’s preference for their work email',
                description:
                  'This will allow the employee to choose which email they prefer for their work email',
              }}
              name="enable_email_selector"
            />

            <TransitionCollapse in={values.enable_email_selector}>
              <VStack p="s-16" space="s-8">
                <LapeRadioSelectInput
                  label="Internal email domain"
                  name="internal_email_domain"
                  message={<EmailDomainMessage type="internal" />}
                  onChange={val => {
                    if (val) {
                      values.internal_email_domain = val.id
                    }
                  }}
                  options={allowedEmailDomains}
                  value={
                    allowedEmailDomains.find(
                      item => item.key === values.internal_email_domain,
                    )?.value
                  }
                />
                <LapeRadioSelectInput
                  label="External email domain"
                  name="external_email_domain"
                  message={<EmailDomainMessage type="external" />}
                  onChange={val => {
                    if (val) {
                      values.external_email_domain = val.id
                    }
                  }}
                  options={allowedEmailDomains}
                  value={
                    allowedEmailDomains.find(
                      item => item.key === values.external_email_domain,
                    )?.value
                  }
                />
              </VStack>
            </TransitionCollapse>
          </Widget>
        </AutoStepper>
      </PageBody>

      <SideBar
        onClose={() => setWelcomePreviewOpen(false)}
        isOpen={welcomePreviewOpen}
        variant="wide"
      >
        <Box mt="-s-32">
          <StartPageView
            joiningDateTime={new Date().toISOString()}
            name="{employee_name}"
            text={values.welcome_page_html}
            title={values.welcome_page_title}
            videoUrl={values.welcome_page_video_url}
          />
        </Box>
      </SideBar>

      <PageActions>
        <NewSaveButtonWithPopup
          isExistingData
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.ONBOARDING_SETTINGS)
          }}
          successText="Settings saved successfully"
          useValidator
        />
      </PageActions>
    </>
  )
}
