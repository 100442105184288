import React from 'react'
import { connect } from 'lape'
import { Statuses } from '@src/interfaces'
import { FilterByInterface, RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { getKpiGradeColumn } from '@src/constants/columns/kpi'
import { GradesMapInterface } from '@src/utils/grades'
import {
  KPIsCardTableProps,
  TableWrapper,
} from '@src/pages/Forms/EmployeePerformanceLayout/components/KPIsCardTable'
import { EntityTypes } from '@src/constants/api'
import { useGetGoalContentTypeFilter } from '@src/features/Goals/useGoalFilters'
import { useTable } from '@components/Table/hooks'
import { goalsListTableRequests, GoalsStats } from '@src/api/goals'
import {
  goalsNameColumn,
  goalsOwnerColumn,
  goalsProgressColumn,
  goalsWeightColumn,
} from '@src/constants/columns/goals'
import isNumber from 'lodash/isNumber'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { GoalsInterface } from '@src/interfaces/goals'

export const getGoalsRow: (
  gradesMap: GradesMapInterface,
  noWidget?: boolean,
) => RowInterface<GoalsInterface> = (gradesMap, noWidget) => ({
  cells: [
    {
      ...goalsNameColumn(goal => pathToUrl(ROUTES.FORMS.GOAL.PREVIEW, { id: goal.id })),
      width: noWidget ? 150 : 300,
    },
    {
      ...goalsWeightColumn,
      width: noWidget ? 92 : 60,
    },
    {
      ...goalsOwnerColumn,
      width: 80,
    },
    {
      ...goalsProgressColumn,
      width: 110,
    },
    {
      ...getKpiGradeColumn(gradesMap),
      width: 110,
    },
  ],
})
interface EmployeeGoalsCardTableContentProps extends KPIsCardTableProps {
  initialFilters: FilterByInterface[]
}

export const EmployeeGoalsCardTableContent = ({
  initialFilters,
  setGoalsStat,
  setGoalsList,
  noWidget,
  gradesMap,
}: EmployeeGoalsCardTableContentProps) => {
  const initialSort = [
    { sortBy: String(goalsWeightColumn.sortKey), direction: SORT_DIRECTION.ASC },
  ]

  const table = useTable(goalsListTableRequests, initialFilters, initialSort, {
    parentIdFilterKey: 'parent_id',
  })

  if (isNumber(table.stats?.overall_progress) && !!setGoalsStat) {
    setGoalsStat(table.stats?.overall_progress)
  }

  if (table.data && !!setGoalsList) {
    setGoalsList(table.data)
  }

  return (
    <TableWrapper noWidget={noWidget}>
      <AdjustableTable<GoalsInterface, GoalsStats>
        name={TableNames.Goals}
        dataType="Goal"
        row={getGoalsRow(gradesMap, noWidget)}
        {...table}
        noDataMessage="No Goals defined"
        onRowClick={data =>
          navigateTo(pathToUrl(ROUTES.FORMS.GOAL.PREVIEW, { id: data.id }))
        }
        noReset
        hideCountAndButtonSection
        expandableType="chevron"
        useFetchedChildren
      />
    </TableWrapper>
  )
}

export const EmployeeGoalsCardTable = connect(
  ({ noWidget = false, employeeId, cycle, ...props }: KPIsCardTableProps) => {
    const contentTypeFilter = useGetGoalContentTypeFilter(EntityTypes.employee)

    if (!contentTypeFilter) {
      return null
    }

    const initialFilters: FilterByInterface[] = [
      ...(cycle?.id
        ? [
            {
              columnName: 'cycle__id',
              filters: [{ id: String(cycle.id), name: String(cycle.name) }],
              nonResettable: true,
            },
          ]
        : []),
      {
        columnName: 'approval_status',
        nonResettable: true,
        filters: [
          { id: Statuses.pending, name: Statuses.pending },
          { id: Statuses.approved, name: Statuses.approved },
          { id: Statuses.requires_changes, name: Statuses.requires_changes },
        ],
      },
      contentTypeFilter,
      ...(employeeId
        ? [
            {
              columnName: 'object_id',
              filters: [{ id: String(employeeId), name: String(employeeId) }],
              nonResettable: true,
              nonInheritable: true,
            },
          ]
        : []),
    ]

    return (
      <EmployeeGoalsCardTableContent
        initialFilters={initialFilters}
        {...props}
        noWidget={noWidget}
      />
    )
  },
)
