import React from 'react'

import { Tag, Ellipsis, Text, IconName } from '@revolut/ui-kit'
import { useIsNewTable } from '@components/TableV2/hooks'

type Props = {
  seniority?: string
  useIcon?: IconName
}

export const SeniorityCell = ({ seniority, useIcon }: Props) => {
  const isNewTable = useIsNewTable()

  if (!seniority) {
    return <>-</>
  }

  if (!isNewTable) {
    return <Text>{seniority}</Text>
  }

  return (
    <Tag variant="faded" style={{ maxWidth: '100%' }} useIcon={useIcon}>
      <Ellipsis>{seniority}</Ellipsis>
    </Tag>
  )
}
