import {
  jiraPreferencesRequests,
  lookerIntegrationPreferences,
} from '@src/api/integrations'
import { RequestInterfaceNew } from '@src/interfaces'
import { UpdateTypes } from '@src/interfaces/kpis'
import { string } from 'zod'

export const integrationApiFormMap: Record<
  UpdateTypes,
  RequestInterfaceNew<{ server: string; enabled: boolean; id: number }> | undefined
> = {
  [UpdateTypes.looker]: lookerIntegrationPreferences,
  [UpdateTypes.roadmap]: jiraPreferencesRequests,
  [UpdateTypes.clickup]: undefined,
  [UpdateTypes.manual]: undefined,
  [UpdateTypes.sql]: undefined,
  [UpdateTypes.tableau]: undefined,
}

export const serverNameValidators = {
  [UpdateTypes.looker]: {
    server: string()
      .trim()
      .url()
      .regex(/^https:\/\/[\w\d-]+\.cloud\.looker.com$/, 'Provide correct account URL'),
  },
  [UpdateTypes.roadmap]: {
    server: string()
      .trim()
      .url()
      .regex(/^https:\/\/[\w\d-]+\.atlassian\.net$/, 'Provide correct server URL'),
  },
  [UpdateTypes.clickup]: undefined,
  [UpdateTypes.manual]: undefined,
  [UpdateTypes.sql]: undefined,
  [UpdateTypes.tableau]: undefined,
} as const
