import React, { useEffect } from 'react'
import { useTable, useTableReturnType, useIsNewTable } from '@components/TableV2/hooks'
import AdjustableTable from '@components/TableV2/AdjustableTable'

import {
  FilterByInterface,
  NotificationComponentType,
  RowInterface,
  SORT_DIRECTION,
  SortByInterface,
} from '@src/interfaces/data'
import { NotificationsInterface } from '@src/interfaces/notifications'
import { tableRequests } from '@src/interfaces'
import { useIsMount } from '@src/utils/isMount'
import { Flex, Placeholder } from '@revolut/ui-kit'
import { NotificationAction } from '@src/pages/Forms/Notifications/NotificationAction/NotificationAction'
import { linkNotificationToForm } from '@src/pages/Forms/Notifications/NotificationAction/utils'
import { TableNames } from '@src/constants/table'
import { withoutFilterAndSort } from '@src/utils/table'
import Table from '@src/components/TableV2/Table'

export const TODO_LIMIT = 5

interface StructureProps {
  sortBy?: SortByInterface[]
  filterBy?: FilterByInterface[]
  rows: RowInterface<NotificationsInterface>
  request: tableRequests<NotificationsInterface, any>
  type?: NotificationComponentType
  onCountChange?: (count?: number) => void
  refreshStats?: (data?: object) => void
  tableRef?: React.MutableRefObject<useTableReturnType<
    NotificationsInterface,
    any
  > | null>
  hiddenCells?: { [key in string]?: boolean }
}

export enum AccessRequestType {
  LookerModel = 'looker_model_access_request',
  LookerFolder = 'looker_folder_access_request',
  Team = 'team_access_request',
  User = 'user_access_request',
}

let tableData: NotificationsInterface[] = []

const GenericNotificationTable = ({
  rows,
  request,
  sortBy,
  filterBy,
  refreshStats,
  type,
  tableRef,
  onCountChange,
  hiddenCells,
}: StructureProps) => {
  const isNewTable = useIsNewTable()
  const isMount = useIsMount()
  const initialSortBy = [
    {
      sortBy: 'priority_level',
      direction: SORT_DIRECTION.ASC,
    },
    {
      sortBy: 'deadline',
      direction: SORT_DIRECTION.DESC,
    },
  ]

  const table = useTable<NotificationsInterface>(
    request,
    filterBy || [],
    sortBy || initialSortBy,
    { disableQuery: type === 'dashboard' },
  )

  useEffect(() => {
    onCountChange?.(table.count)
  }, [table.count])

  useEffect(() => {
    if (!isMount) {
      table.resetFiltersAndSorting(filterBy || [], sortBy || initialSortBy)
    }
  }, [filterBy])

  useEffect(() => {
    if (tableRef) {
      tableRef.current = table
    }
  }, [])

  useEffect(() => {
    if (!table.loading) {
      tableData = table.data.map((d, id) => ({ ...d, id: String(id) }))
      table.setData(tableData)
    }
  }, [table.loading])

  const clean = (id: string) => {
    tableData = tableData.filter(d => d.id !== id)
    table.setData(tableData)
    refreshStats && refreshStats(tableData)
  }

  rows.cells[rows.cells.length - 1].notHoverable = true
  rows.cells[rows.cells.length - 1].insert = ({ data }) => (
    <Flex gap="s-16">
      <NotificationAction data={data} onSuccess={clean} />
    </Flex>
  )

  rows.linkToForm = data => linkNotificationToForm(data)

  if (type === 'dashboard') {
    return (
      <AdjustableTable
        name={TableNames.Notifications}
        dataType="to do"
        row={{
          ...rows,
          cells: withoutFilterAndSort(rows.cells).map(c => ({
            ...c,
            width: c.width / 2,
          })),
        }}
        {...table}
        data={table.data?.slice(0, TODO_LIMIT)}
        noDataMessage={
          isNewTable ? (
            <Placeholder>
              <Placeholder.Image
                image={{
                  default: 'https://assets.revolut.com/assets/3d-images-v2/3D021.png',
                  '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D021@2x.png',
                  '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D021@3x.png',
                }}
              />
              <Placeholder.Title>All caught up</Placeholder.Title>
              <Placeholder.Description>
                Your open To do’s will appear here
              </Placeholder.Description>
            </Placeholder>
          ) : (
            "There are no to do's assigned to you at the moment"
          )
        }
        enableSettings={false}
        hideCount
        hideCountAndButtonSection
        hideHeader
        hiddenCells={hiddenCells}
        rowHeight="large"
      />
    )
  }

  return (
    <Table.Widget>
      <AdjustableTable<NotificationsInterface>
        name={TableNames.Notifications}
        useWindowScroll
        dataType="to do"
        row={rows}
        {...table}
        noReset
        noDataMessage="To do's will appear here."
        enableSettings={false}
      />
    </Table.Widget>
  )
}

export default GenericNotificationTable
