import React, { ReactNode } from 'react'
import { Text, Token } from '@revolut/ui-kit'
import { UpdateTypes } from '@src/interfaces/kpis'

export const integrationPopupContentMap: Record<
  UpdateTypes,
  { label: string | undefined; description: ReactNode }
> = {
  // there is no integration for SQL / Manual so in reality it'd never happen but to keep types aligned
  // adding them as well
  [UpdateTypes.sql]: {
    label: undefined,
    description: undefined,
  },
  [UpdateTypes.manual]: {
    label: undefined,
    description: undefined,
  },
  [UpdateTypes.roadmap]: {
    label: 'Jira server URL',
    description: (
      <Text variant="body2" color={Token.color.greyTone50}>
        Your Jira account URL is your unique identifier followed by{' '}
        <Text color={Token.color.foreground}>atlassian.net</Text>, for example{' '}
        <Text color={Token.color.foreground}>https://revolut.atlassian.net</Text>.
      </Text>
    ),
  },
  [UpdateTypes.looker]: {
    label: 'Looker account URL',
    description: (
      <Text variant="body2" color={Token.color.greyTone50}>
        Your Looker account URL is your unique identifier, followed by{' '}
        <Text color={Token.color.foreground}>cloud.looker.com</Text>, for example{' '}
        <Text color={Token.color.foreground}>https://revolut.cloud.looker.com</Text>.
      </Text>
    ),
  },
  [UpdateTypes.clickup]: {
    label: 'ClickUp instance URL',
    description: (
      <Text variant="body2" color={Token.color.greyTone50}>
        Your ClickUp instance URL starts with{' '}
        <Text color={Token.color.foreground}>https://api.clickup.com/api/team/</Text>{' '}
        followed by your unique tenant name, for example{' '}
        <Text color={Token.color.foreground}>
          {'https://api.clickup.com/api/team/{tenant-name}'}
        </Text>
        .
      </Text>
    ),
  },
  [UpdateTypes.tableau]: {
    label: 'Tableau instance URL',
    description: (
      <Text variant="body2" color={Token.color.greyTone50}>
        Your Tableau instance URL contains your tenant_id and your{' '}
        <Text color={Token.color.foreground}>site_id</Text>, for example{' '}
        <Text color={Token.color.foreground}>
          {'https://tableau-server-{tenant_id}.com/api/3.9/sites/{site_id}/workbooks'}
        </Text>
        .
      </Text>
    ),
  },
}
