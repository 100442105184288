import { InputGroup } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { KpiInterface } from '@src/interfaces/kpis'
import React, { useEffect, useState } from 'react'
import { QueryField } from './QueryField'
import { mergeFormValues } from '@src/utils/form'
import { LapeCodeEditorProps } from '@src/components/Inputs/LapeFields/LapeNewCodeEditor'
import { ProgressDirection } from './components/ProgressDirection'
import { UnitOfMeasure } from './components/UnitOfMeasure'
import { InitTargetValues } from './components/InitTargetValues'

export const TargetFormFields = ({
  hideLooker = false,
  codeEditorProps,
  variant,
}: {
  hideLooker?: boolean
  codeEditorProps?: Partial<LapeCodeEditorProps>
  variant?: 'new' | 'side-edit'
}) => {
  const { values, changelog } = useLapeContext<KpiInterface>()

  const [previousValues, setPreviousValues] = useState<KpiInterface | undefined>()

  useEffect(() => {
    setPreviousValues(changelog && mergeFormValues(values, changelog))
  }, [values, changelog])

  return (
    <InputGroup>
      <UnitOfMeasure variant={variant} />
      <ProgressDirection />
      {variant === 'new' ? (
        <InputGroup variant="horizontal">
          <InitTargetValues />
        </InputGroup>
      ) : (
        <InitTargetValues />
      )}

      <QueryField
        hideLooker={hideLooker}
        previousQuery={previousValues?.sql_query}
        codeEditorProps={codeEditorProps}
      />
    </InputGroup>
  )
}
