import React, { useEffect, useMemo, useState } from 'react'
import { FilterByInterface, SORT_DIRECTION } from '@src/interfaces/data'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable } from '@components/TableV2/hooks'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Bar, chain, FilterButton, MoreBar } from '@revolut/ui-kit'
import {
  CandidatesListInterface,
  InterviewRoundState,
} from '@src/interfaces/interviewTool'
import { interviewCandidatesRequests } from '@src/api/recruitment/interviews'
import { LOCAL_STORAGE } from '@src/constants/api'
import { useSelector } from 'react-redux'
import { selectPermissions, selectUser } from '@src/store/auth/selectors'
import { SpecialisationInterface } from '@src/interfaces/roles'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import { PermissionTypes } from '@src/store/auth/types'
import SelectTableWrapperV2, {
  SelectTableWrapperOnChangeData,
} from '@components/TableV2/AdvancedCells/SelectCell/SelectTableWrapper'
import SelectTableWrapperV1 from '@components/Table/AdvancedCells/SelectCell/SelectTableWrapper'
import CandidatesPipelineFilter, {
  getCandidatesPipelineFilters,
  getSelectedTab,
} from '@components/CandidatesPipelineFilter/CandidatesPipelineFilter'
import FiltersSidebar, {
  getAppliedFiltersCount,
} from '@components/FiltersSidebar/FiltersSidebar'
import CandidateSendEmailSidebar from '@components/CandidateSendEmailSidebar/CandidateSendEmailSidebar'
import ArchivingOpportunitySidebar from '@components/ArchivingCandidateSidebar/ArchivingCandidateSidebar'
import { AnalyticsEvents, useAnalytics } from '@src/utils/analytics'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import { HiringPipelineInterface } from '@src/interfaces/hiringPipelines'
import { useGetCandidatesPipelineView } from '@src/api/recruitment/candidates'
import ScreenCandidatesButton from '@src/features/CommonCandidatesTable/ScreenCandidatesButton'
import ReassignRecruiterSidebar, {
  ReassignRecruiterAction,
} from './ReassignRecruiterSidebar'
import MoveToStageSidebar, {
  MoveToStageAction,
} from '@src/features/CommonCandidatesTable/MoveToStageSidebar'
import map from 'lodash/map'
import uniqBy from 'lodash/uniqBy'
import SendOnlineTestSidebar, { SendOnlineTestAction } from './SendOnlineTestSidebar'
import { useGetCandidateSettings, useGetHiringProcessSettings } from '@src/api/settings'
import { getConfidentialFilter } from '../CommonRequisitionTable/ShowConfidentialFilter'
import {
  BulkSnoozeAction,
  BulkSnoozePopup,
} from '@src/pages/Forms/Candidate/InterviewProgress/components/Snooze'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { useFetchBulkIds } from '@src/hooks/useFetchBulkIds'
import { SettingsButton } from '../SettingsButtons/SettingsButton/SettingsButton'
import { settingsConfig } from '@src/pages/Settings/SettingsLandingPage/constants'
import { BookingLinkAction } from '@src/features/CommonCandidatesTable/Actions/BookingLinkAction'
import { OffersAction } from '@src/features/CommonCandidatesTable/Actions/OffersAction'
import { EmailTemplatesAction } from '@src/features/CommonCandidatesTable/Actions/EmailTemplatesAction'
import { CRMAction } from '@src/features/CommonCandidatesTable/Actions/CRMAction'
import { ReferralBonusAction } from '@src/features/CommonCandidatesTable/Actions/ReferralBonusAction'
import { OnboardingAppScreen } from '@src/pages/OnboardingChecklistV2/components/OnboardingAppScreen'
import { ViewBulkAction } from '@src/features/CommonCandidatesTable/Actions/ViewBulkAction'
import { useSaveSwitcherItems } from '@components/SwitcherButtonsWIthSidebar/useSaveSwitcherItems'
import Table from '@components/TableV2/Table'
import {
  CandidatesTableOpenSidebarType,
  CandidatesTableType,
} from '@src/features/CommonCandidatesTable/types'
import { candidatesTableRow } from '@src/features/CommonCandidatesTable/candidatesTableRow'
import { candidateTableFilters } from '@src/features/CommonCandidatesTable/candidateTableFilters'
import { useIsNewTable } from '@components/TableV2/hooks'
import {
  candidateActionColumn,
  candidateInterviewerColumn,
  candidateLastActivityDateColumn,
  candidateNextInterviewColumn,
  candidateOfferApprovalStatusColumn,
  candidateOfferLocationColumn,
  candidateOfferStartDateColumn,
  candidateOfferTeamColumn,
  candidateOriginColumn,
  candidateRecruiterColumn,
  candidateSnoozedUntilDateColumn,
  candidateStateColumn,
  candidateWorkExperienceColumn,
  candidateYearsOfExperienceColumn,
  createCandidateRequisitionColumn,
} from '@src/constants/columns/candidates'

interface Props {
  data?: SpecialisationInterface | RequisitionInterface | HiringPipelineInterface
  type: CandidatesTableType
  filterBy?: FilterByInterface[]
  compact?: boolean
  children?: React.ReactNode
}

const CommonCandidatesTableBase = ({
  data,
  type,
  filterBy,
  compact,
  children,
}: Props) => {
  const isNewTable = useIsNewTable()
  const { sendAnalyticsEvent } = useAnalytics()
  const user = useSelector(selectUser)
  const permissions = useSelector(selectPermissions)
  const [isConfidential, setIsConfidential] = useState<boolean>()
  const [showAssignedToMe, setShowAssignedToMe] = useState(
    !!workspaceLocalStorage.getItem(LOCAL_STORAGE.SHOW_CANDIDATES_ASSIGNED_TO_ME),
  )
  const [showArchived, setShowArchived] = useState(
    !!workspaceLocalStorage.getItem(LOCAL_STORAGE.SHOW_ARCHIVED_CANDIDATES),
  )
  const [selectedData, setSelectedData] =
    useState<SelectTableWrapperOnChangeData<CandidatesListInterface>>()
  const [openSidebar, setOpenSidebar] = useState<CandidatesTableOpenSidebarType>()

  const canAddCandidate = permissions?.includes(PermissionTypes.AddRecruitmentCandidate)
  const canImportCandidates = permissions?.includes(
    PermissionTypes.RecruitmentPermissions,
  )
  const { data: candidateSettings } = useGetCandidateSettings()
  const { data: hiringProcessSettings } = useGetHiringProcessSettings()

  const getFilterByRecruiter = (setFilter: boolean) => ({
    filters: setFilter
      ? [
          {
            name: user.display_name,
            id: user.id,
          },
        ]
      : [],
    columnName: 'recruiter',
    nonResettable: true,
  })

  const getFilterByState = (setFilter: boolean) => ({
    filters: setFilter
      ? [
          { id: InterviewRoundState.active, name: InterviewRoundState.active },
          { id: InterviewRoundState.archived, name: InterviewRoundState.archived },
          { id: InterviewRoundState.hired, name: InterviewRoundState.hired },
        ]
      : [{ id: InterviewRoundState.active, name: InterviewRoundState.active }],
    columnName: 'active_interview_round__state',
    nonResettable: true,
  })

  const getInitialFilter = () => {
    const filter: FilterByInterface[] = [
      ...(filterBy || []),
      getConfidentialFilter(),
      {
        filters: [
          {
            name: 'False',
            id: 'False',
          },
        ],
        columnName: 'is_snoozed',
        nonResettable: true,
      },
    ]

    if (data) {
      if (type === 'specialisation') {
        filter.push({
          filters: [{ name: (data as SpecialisationInterface).name, id: data.id }],
          columnName: 'active_interview_round__specialisation',
          nonResettable: true,
        })
      }

      if (type === 'requisition') {
        const specialisation = (data as RequisitionInterface).specialisation

        filter.push({
          filters: [
            {
              name: specialisation.name,
              id: specialisation.id,
            },
          ],
          columnName: 'active_interview_round__specialisation',
          nonResettable: true,
        })
      }

      if (type === 'hiringPipeline') {
        const hiringPipelineId = (data as HiringPipelineInterface).id

        filter.push({
          filters: [
            {
              name: String(hiringPipelineId),
              id: hiringPipelineId,
            },
          ],
          columnName: 'active_hiring_pipeline',
          nonResettable: true,
        })
      }
    }

    if (showAssignedToMe) {
      filter.push(getFilterByRecruiter(true))
    }

    filter.push(getFilterByState(showArchived))

    return filter
  }

  const initialSortBy = [
    {
      sortBy: 'inactivity_reason',
      direction: SORT_DIRECTION.ASC,
    },
  ]
  const initialFilter = uniqBy(getInitialFilter(), 'columnName')

  const table = useTable<CandidatesListInterface, undefined>(
    interviewCandidatesRequests,
    initialFilter,
    initialSortBy,
  )

  useSaveSwitcherItems<CandidatesListInterface>(
    LOCAL_STORAGE.CANDIDATES_TABLE_CONTEXT,
    table.data,
    'full_name',
  )

  useEffect(() => {
    if (filterBy) {
      table.onFilterChange(filterBy)
    }
  }, [filterBy])

  const { data: candidatesPipelineData } = useGetCandidatesPipelineView(
    getCandidatesPipelineFilters(table.filterBy),
  )

  const onToggleMyCandidates = () => {
    if (showAssignedToMe) {
      workspaceLocalStorage.removeItem(LOCAL_STORAGE.SHOW_CANDIDATES_ASSIGNED_TO_ME)
    } else {
      workspaceLocalStorage.setItem(LOCAL_STORAGE.SHOW_CANDIDATES_ASSIGNED_TO_ME, 'true')
    }
    setShowAssignedToMe(!showAssignedToMe)
    table.onFilterChange(getFilterByRecruiter(!showAssignedToMe))
  }

  const onToggleArchivedCandidates = () => {
    if (showArchived) {
      workspaceLocalStorage.removeItem(LOCAL_STORAGE.SHOW_ARCHIVED_CANDIDATES)
    } else {
      workspaceLocalStorage.setItem(LOCAL_STORAGE.SHOW_ARCHIVED_CANDIDATES, 'true')
    }
    setShowArchived(!showArchived)
    table.onFilterChange(getFilterByState(!showArchived))
  }

  const [
    selectedIds,
    selectedCandidates,
    selectedSpecialisationIds,
    allCandidatesAreSnoozed,
  ] = useMemo(() => {
    let candidateIds = selectedData?.selectedRowsIds
      ? Array.from(selectedData?.selectedRowsIds)
      : []
    if (selectedData?.isAllSelected) {
      candidateIds = table.data.reduce<string[]>((acc, item) => {
        if (!selectedData?.excludeListIds?.has(String(item.id))) {
          acc.push(String(item.id))
        }

        return acc
      }, [])
    }
    const candidates = table.data.filter(({ id }) => candidateIds.includes(String(id)))
    const specialisations = uniqBy(
      map(candidates, 'active_interview_round.specialisation'),
      'id',
    )
    const specialisationIds = map(specialisations, 'id')
    const snoozed = candidates.every(({ active_snoozing }) => active_snoozing)
    return [candidateIds, candidates, specialisationIds, snoozed]
  }, [selectedData, table.data])
  const bulkActionMode = !!selectedIds.length

  const { bulkIds, fetchBulkIds } = useFetchBulkIds<CandidatesListInterface>(
    '/interviews/candidates',
    table,
    selectedData,
  )

  const stageType = getSelectedTab(table.filterBy, candidatesPipelineData)?.stage_type
  const isOfferStage = stageType === 'offer'
  const showStatus = !!table.filterBy
    .find(item => item.columnName === 'active_interview_round__state')
    ?.filters?.some(item => item.id !== 'active')
  const showSnoozedUntil = !!table.filterBy
    .find(item => item.columnName === 'is_snoozed')
    ?.filters?.some(item => item.id === 'True')
  const disabledBulkActions = !selectedIds.length && !selectedData?.isAllSelected

  const searchBar = (
    <Table.Search
      placeholder="Search by name, email, role, current company"
      onFilter={table.onFilterChange}
    />
  )

  const tableSettings = useMemo(() => {
    if (isNewTable) {
      return {
        visible: [],
        hidden: isOfferStage
          ? [
              candidateLastActivityDateColumn.title,
              candidateOriginColumn.title,
              candidateOfferStartDateColumn.title,
              candidateWorkExperienceColumn.title,
              candidateYearsOfExperienceColumn.title,
              candidateInterviewerColumn.title,
              candidateNextInterviewColumn.title,
              candidateActionColumn.title,
              candidateStateColumn.title,
              createCandidateRequisitionColumn().title,
              candidateSnoozedUntilDateColumn.title,
            ]
          : [
              candidateLastActivityDateColumn.title,
              candidateOriginColumn.title,
              candidateRecruiterColumn.title,
              candidateOfferStartDateColumn.title,
              candidateWorkExperienceColumn.title,
              candidateYearsOfExperienceColumn.title,
              candidateOfferLocationColumn.title,
              candidateOfferTeamColumn.title,
              candidateOfferApprovalStatusColumn.title,
              candidateInterviewerColumn.title,
              candidateNextInterviewColumn.title,
              candidateActionColumn.title,
              candidateStateColumn.title,
              createCandidateRequisitionColumn().title,
              candidateSnoozedUntilDateColumn.title,
            ],
      }
    }
    return {
      hidden: [createCandidateRequisitionColumn().title],
      visible: [],
    }
  }, [isNewTable, isOfferStage])

  const SelectTableWrapper = useMemo(
    () => (isNewTable ? SelectTableWrapperV2 : SelectTableWrapperV1),
    [isNewTable],
  )

  return (
    <>
      <Table.Widget>
        {compact ? (
          <>
            <Table.Widget.Info>{children}</Table.Widget.Info>
            <Table.Widget.Search>{searchBar}</Table.Widget.Search>
          </>
        ) : (
          <>
            <Table.Widget.Info>
              <CandidatesPipelineFilter
                filters={table.filterBy}
                onFilterChange={table.onFilterChange}
              />
            </Table.Widget.Info>
            <Table.Widget.Search>{searchBar}</Table.Widget.Search>
            <Table.Widget.Actions>
              <Table.Widget.MoreBar maxCount={3} labelMoreButton="More candidate actions">
                {!bulkActionMode && (
                  <>
                    {canAddCandidate && (
                      <MoreBar.Action
                        useIcon="Plus"
                        use={InternalLink}
                        to={pathToUrl(ROUTES.FORMS.NEW_CANDIDATE.GENERAL)}
                      >
                        Add candidate
                      </MoreBar.Action>
                    )}
                    {type === 'common' && (
                      <>
                        <BookingLinkAction />
                        <SettingsButton
                          path={ROUTES.SETTINGS.CANDIDATES.LIST}
                          canView={settingsConfig.candidates.canView}
                        />
                        <CRMAction />
                        <EmailTemplatesAction />
                        <ViewBulkAction />
                        <OffersAction />
                        <ReferralBonusAction />
                        {canImportCandidates && (
                          <MoreBar.Action
                            use={InternalLink}
                            to={pathToUrl(
                              ROUTES.FORMS.IMPORT_DATA.CANDIDATES.UPLOAD_FILE,
                            )}
                            useIcon="ShareIOs"
                          >
                            Import candidates
                          </MoreBar.Action>
                        )}
                      </>
                    )}
                  </>
                )}
                {stageType === 'cv_screening' && (
                  <ScreenCandidatesButton
                    candidates={selectedCandidates}
                    disabled={disabledBulkActions}
                  />
                )}
                {bulkActionMode ? (
                  <>
                    {candidateSettings?.enable_emails && (
                      <MoreBar.Action
                        onClick={async () => {
                          sendAnalyticsEvent(AnalyticsEvents.click_bulk_send_emails_btn)
                          await fetchBulkIds()
                          setOpenSidebar('sendEmails')
                        }}
                        disabled={disabledBulkActions}
                        useIcon="Envelope"
                      >
                        Send emails
                      </MoreBar.Action>
                    )}
                    <MoreBar.Action
                      onClick={async () => {
                        sendAnalyticsEvent(AnalyticsEvents.click_bulk_archive_btn)
                        await fetchBulkIds()
                        setOpenSidebar('archive')
                      }}
                      disabled={disabledBulkActions}
                      useIcon="Archive"
                      variant="negative"
                    >
                      Archive all
                    </MoreBar.Action>
                    <BulkSnoozeAction
                      disabled={false}
                      unsnooze={false}
                      onClick={async () => {
                        await fetchBulkIds()
                        setOpenSidebar('snooze')
                      }}
                    />
                    <BulkSnoozeAction
                      disabled={!allCandidatesAreSnoozed}
                      unsnooze
                      onClick={async () => {
                        await fetchBulkIds()
                        setOpenSidebar('unsnooze')
                      }}
                    />
                    <ReassignRecruiterAction
                      onClick={async () => {
                        await fetchBulkIds()
                        setOpenSidebar('reassign-recruiter')
                      }}
                      disabled={disabledBulkActions}
                    />

                    <MoveToStageAction
                      disabled={selectedSpecialisationIds.length > 1}
                      onClick={async () => {
                        await fetchBulkIds()
                        setOpenSidebar('move-to-stage')
                      }}
                    />
                    {hiringProcessSettings?.enable_online_test_stage && (
                      <SendOnlineTestAction
                        disabled={selectedSpecialisationIds.length > 1}
                        onClick={async () => {
                          await fetchBulkIds()
                          setOpenSidebar('send-online-test')
                        }}
                      />
                    )}
                  </>
                ) : (
                  <Table.ColumnsSettingsButton />
                )}
              </Table.Widget.MoreBar>
            </Table.Widget.Actions>
            <Table.Widget.Filters>
              <Bar>
                <FilterButton onClick={onToggleArchivedCandidates} active={showArchived}>
                  Archived
                </FilterButton>
                <FilterButton onClick={onToggleMyCandidates} active={showAssignedToMe}>
                  My candidates
                </FilterButton>
                <FilterButton
                  useIcon="Filter"
                  onClick={() => {
                    sendAnalyticsEvent(AnalyticsEvents.click_candidates_table_filter_btn)
                    setOpenSidebar(prevState =>
                      prevState === 'filters' ? undefined : 'filters',
                    )
                  }}
                  onClear={() => {
                    table.resetFiltersAndSorting()
                  }}
                >
                  {chain(
                    'Filters',
                    getAppliedFiltersCount(table.filterBy, candidateTableFilters),
                  )}
                </FilterButton>
              </Bar>
            </Table.Widget.Filters>
          </>
        )}
        <Table.Widget.Table>
          <SelectTableWrapper
            enabled
            onChange={setSelectedData}
            filters={table.filterBy}
            tableDataLength={table.data.length}
          >
            <AdjustableTable<CandidatesListInterface>
              name={TableNames.CommonCandidates}
              useWindowScroll
              dataType="Candidate"
              row={candidatesTableRow({
                showAssignedToMe,
                showStatus,
                showSnoozedUntil: isNewTable ? true : showSnoozedUntil,
                type,
                isConfidential,
                stageType,
                isNewTable,
              })}
              {...table}
              noDataMessage="Candidates will appear here. Please check your referred candidates in your HR profile"
              hideCount={!!isNewTable}
              tableSettings={tableSettings}
            />
          </SelectTableWrapper>
        </Table.Widget.Table>
      </Table.Widget>

      <FiltersSidebar
        open={openSidebar === 'filters'}
        onClose={() => setOpenSidebar(undefined)}
        items={candidateTableFilters}
        filters={table.filterBy}
        onFilter={(filters, resetDefaultFilters, forceResetOnFilter) => {
          table.onFilterChange(
            filters.map(f => {
              if (f.columnName === 'is_confidential') {
                const value = f.filters[0].id === 'True'
                setIsConfidential(value ? true : undefined)
                return getConfidentialFilter(value ? true : undefined)
              }
              return f
            }),
            resetDefaultFilters,
            forceResetOnFilter,
          )
        }}
      />

      {candidateSettings?.enable_emails && (
        <CandidateSendEmailSidebar
          isOpen={openSidebar === 'sendEmails'}
          onClose={() => setOpenSidebar(undefined)}
          candidateIds={bulkIds}
          bulk
        />
      )}

      <ArchivingOpportunitySidebar
        candidateIds={bulkIds}
        open={openSidebar === 'archive'}
        onClose={() => setOpenSidebar(undefined)}
        onAfterArchive={() => setOpenSidebar(undefined)}
      />

      <ReassignRecruiterSidebar
        candidateIds={bulkIds}
        open={openSidebar === 'reassign-recruiter'}
        onClose={() => setOpenSidebar(undefined)}
      />

      <MoveToStageSidebar
        candidateIds={bulkIds}
        specialisationIds={selectedSpecialisationIds}
        open={openSidebar === 'move-to-stage'}
        onClose={() => setOpenSidebar(undefined)}
      />

      {openSidebar === 'send-online-test' && (
        <SendOnlineTestSidebar
          candidates={selectedCandidates}
          candidateIds={bulkIds}
          specialisationIds={selectedSpecialisationIds}
          open
          onClose={() => setOpenSidebar(undefined)}
        />
      )}

      <BulkSnoozePopup
        open={openSidebar === 'snooze'}
        onClose={() => setOpenSidebar(undefined)}
        unsnooze={false}
        onAfterSubmit={() => {
          table.refresh()
          setSelectedData(undefined)
        }}
        candidateIds={bulkIds}
      />

      <BulkSnoozePopup
        open={openSidebar === 'unsnooze'}
        onClose={() => setOpenSidebar(undefined)}
        onAfterSubmit={() => {
          table.refresh()
          setSelectedData(undefined)
        }}
        candidateIds={bulkIds}
        unsnooze
      />
    </>
  )
}

const CommonCandidatesTable = (props: Props) => {
  if (props.type === 'common') {
    return (
      <OnboardingAppScreen category="candidates">
        <CommonCandidatesTableBase {...props} />
      </OnboardingAppScreen>
    )
  }

  return <CommonCandidatesTableBase {...props} />
}

export default CommonCandidatesTable
