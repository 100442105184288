import React, { useEffect, useState } from 'react'
import { pathToUrl } from '@src/utils/router'
import { Route, Switch, useParams } from 'react-router-dom'
import { HStack, Tag, Text, TextSkeleton, Token } from '@revolut/ui-kit'

import { useGetPayCycle } from '@src/api/payrollV2'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { ROUTES } from '@src/constants/routes'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'

import { PayCycleTabChanges } from './TabChanges'
import { PayCycleTabComments } from './TabComments'
import { PayCycleTabOverview } from './TabOverview'
import { useGetPayCyclesOptions } from './CycleSelector/hooks'
import { CycleOption } from './common'

type RouteParams = { id: string }

const getTabs = (params: RouteParams) => [
  {
    key: 'overview',
    path: ROUTES.APPS.PAYROLL_V2.PAY_CYCLE.OVERVIEW,
    to: pathToUrl(ROUTES.APPS.PAYROLL_V2.PAY_CYCLE.OVERVIEW, params),
    title: 'Overview',
    component: PayCycleTabOverview,
  },
  {
    key: 'changes',
    path: ROUTES.APPS.PAYROLL_V2.PAY_CYCLE.CHANGES,
    to: pathToUrl(ROUTES.APPS.PAYROLL_V2.PAY_CYCLE.CHANGES, params),
    title: 'Changes',
    component: PayCycleTabChanges,
  },
  {
    key: 'comments',
    path: ROUTES.APPS.PAYROLL_V2.PAY_CYCLE.COMMENTS,
    to: pathToUrl(ROUTES.APPS.PAYROLL_V2.PAY_CYCLE.COMMENTS, params),
    title: 'Comments',
    component: PayCycleTabComments,
  },
]

export const PayCycle = () => {
  const params = useParams<RouteParams>()
  const tabs = getTabs(params)

  const [selectedCycle, setSelectedCycle] = useState<CycleOption>()

  const { data: payCycleData, isLoading: isPayCycleDataLoading } = useGetPayCycle(
    params.id,
  )
  const groupName = payCycleData?.pay_group.name
  const title = isPayCycleDataLoading ? (
    <TextSkeleton height="s-24" width={360} />
  ) : (
    <Text>{groupName}</Text>
  )
  const labels = isPayCycleDataLoading ? (
    <TextSkeleton height="s-8" width={120} />
  ) : (
    payCycleData?.pay_group && (
      <HStack>
        <Tag variant="status" useIcon="Bank">
          {payCycleData.pay_group.payroll_provider}
        </Tag>
      </HStack>
    )
  )

  const groupId = payCycleData?.pay_group.id
  const { options: cyclesOptions, isLoading: isCyclesOptionsDataLoading } =
    useGetPayCyclesOptions(groupId ? String(groupId) : undefined)
  const isCycleSelectorLoading = isPayCycleDataLoading || isCyclesOptionsDataLoading

  useEffect(() => {
    if (selectedCycle) {
      return
    }
    const currentCycle = cyclesOptions.find(cycle => cycle.statusId === 'open')

    if (currentCycle) {
      setSelectedCycle(currentCycle)
    }
  }, [selectedCycle, cyclesOptions])

  return (
    <Switch>
      <Route path={ROUTES.APPS.PAYROLL_V2.PAY_CYCLE.ANY}>
        <PageWrapper>
          <PageHeader
            title={<PageHeader.Title title={title} labels={labels} useIcon="Coins" />}
            backUrl={ROUTES.APPS.PAYROLL_V2.PAY_GROUP.LIST}
          />
          <PageBody maxWidthMd={Token.breakpoint.xxl}>
            <TabBarNavigation tabs={tabs} mb="s-16" />
            <Switch>
              {tabs.map(tab => (
                <Route exact key={tab.key} path={tab.path}>
                  <tab.component
                    selectedCycle={selectedCycle}
                    setSelectedCycle={setSelectedCycle}
                    cycleSelectorOptions={cyclesOptions}
                    isCycleSelectorLoading={isCycleSelectorLoading}
                  />
                </Route>
              ))}
            </Switch>
          </PageBody>
        </PageWrapper>
      </Route>
    </Switch>
  )
}
