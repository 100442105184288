import React from 'react'

import { PayCycleEmployeeReportsTable } from './EmployeeReportsTable'
import { CommonTabProps } from './common'

type Props = CommonTabProps
export const PayCycleTabOverview = (props: Props) => {
  return (
    <>
      {/** TODO: RHR-5780 Add report details sidebar */}
      <PayCycleEmployeeReportsTable {...props} />
    </>
  )
}
