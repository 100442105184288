import { string } from 'zod'

export const tryToMakeEmbedLookerUrl = (maybeUrl?: string | null) => {
  if (!maybeUrl) {
    return null
  }
  try {
    const plainUrl = new URL(maybeUrl)
    plainUrl.pathname = `/embed${plainUrl.pathname}`
    return plainUrl
  } catch (err) {
    // that's ok. maybe users just typing
  }

  return null
}

export const lookerValidator = string()
  .trim()
  .url()
  .regex(/^https:\/\/[\w\d-]+\.cloud\.looker\.com\/[\w\d/\-._]*$/)
