import { Token, MoreBar, MoreBarSkeleton, Flex } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'
import { useTable } from '@src/components/Table/hooks'
import {
  GoalsStats,
  ManageGoalsPayload,
  SupportedEntityGraphPath,
  fetchEntityGoalsGraph,
  goalsListTableRequests,
} from '@src/api/goals'
import {
  OrgEntityInterface,
  useOrgEntity,
} from '@src/features/OrgEntityProvider/OrgEntityProvider'
import React, { useEffect, useMemo, useState } from 'react'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'
import {
  goalsInputWeightColumn,
  goalsNameColumn,
  goalsOwnerColumn,
  goalsWeightColumn,
  goalsApprovalStatusColumn,
  goalsChangeStatusColumn,
  goalsOrgUnitColumn,
  singleTargetAdditionalColumns,
  goalsProgressColumn,
} from '@src/constants/columns/goals'
import { EntityTypes, selectorKeys } from '@src/constants/api'
import {
  FilterByInterface,
  RowInterface,
  SORT_DIRECTION,
  SortByInterface,
} from '@src/interfaces/data'
import { AddGoalAction } from './components/AddGoalAction'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { BulkGoalApproveAction } from './components/BulkGoalApproveAction'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { groupFiltersIntoURLQuery } from '@src/utils/table'
import { GoalContentType, GoalKpiDetails, GoalsInterface } from '@src/interfaces/goals'
import LapeForm, { useLapeContext } from '../Form/LapeForm'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '../Form/Buttons/NewSaveButtonWithPopup'
import LapeEditableTable from '@src/components/TableV2/EditableTable/LapeEditableTable'
import { EditableRowInterface } from '@src/components/TableV2/EditableTable/EditableTable'
import { Statuses } from '@src/interfaces'
import { GoalTargetSidebarForm } from './GoalTargetSidebarForm'
import { OverallProgress } from './components/OverallProgress'
import { GoalCycleFilter } from '@src/features/Goals/components/GoalCycleFilter'
import { rowHighlight } from '../KPI'
import { PermissionTypes } from '@src/store/auth/types'
import { useManageGoalsWeights } from './useManageGoalsWeights'
import { getLocationDescriptor, navigateTo } from '@src/actions/RouterActions'
import { isOnboardingPath } from '@src/pages/OnboardingChecklistV2/common/helpers'
import { selectPermissions } from '@src/store/auth/selectors'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { GoalsSettingsButton } from './components/GoalsSettingsButton'
import { GoalLevelSelector } from './components/GoalLevelSelector'
import { useGetSelectors } from '@src/api/selectors'
import { isEqual, isNumber } from 'lodash'
import { useRouteMatch, useLocation } from 'react-router-dom'
import { CascadeGoalAction } from '@src/features/Goals/components/CascadeGoalAction'
import { EmptyGoalTable } from './components/EmptyGoalTable'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import Table from '@src/components/TableV2/Table'

interface GoalsTableWidgetProps {
  initialFilters: FilterByInterface[]
  entity: OrgEntityInterface
  onManageModeChange?: (enabled: boolean) => void
}

export const GoalsTableWidget = ({
  initialFilters,
  entity,
  onManageModeChange,
}: GoalsTableWidgetProps) => {
  const permissions = useSelector(selectPermissions)
  const { data: contentTypeData } = useGetSelectors<GoalContentType>(
    selectorKeys.goal_content_types,
    undefined,
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  )
  const initialSort: SortByInterface[] | undefined = [
    EntityTypes.company,
    EntityTypes.companyV2,
  ].includes(entity.type)
    ? undefined
    : [{ sortBy: String(goalsWeightColumn.sortKey), direction: SORT_DIRECTION.ASC }]

  const table = useTable(goalsListTableRequests, initialFilters, initialSort, {
    parentIdFilterKey: 'parent_id',
  })
  const [preselectedType, setPreselectedType] = useState<
    EntityTypes.companyV2 | EntityTypes.department | EntityTypes.team
  >()
  const [isAddButtonHidden, setIsAddButtonHidden] = useState(false)
  const [cycleCategory, setCycleCategory] = useState<ReviewCycleCategory>()
  const { data: performanceSettings } = useGetPerformanceSettings()
  const isMultipleGoalsTargetsEnabled =
    performanceSettings?.enable_multiple_goal_targets_per_cycle
  const form = useLapeContext<ManageGoalsPayload>()
  const contentType = table.data[0]?.content_type?.id
  const {
    manageMode,
    handleSubmit,
    weightMode,
    toggleManageMode,
    confirmationDialog,
    autoDistributeWeights,
    validationMessage,
  } = useManageGoalsWeights({
    entity,
    contentType,
    tableData: table.data,
    cycleId: table.filterBy.find(f => f.columnName === 'cycle__id')?.filters[0]?.id,
    onUpdated: () => table.refresh(),
    cycleCategory,
  })
  const [selectedTarget, setSelectedTarget] = useState<GoalKpiDetails>()
  const location = useLocation<{ selectedCycle?: boolean }>()
  const initialFilterCycle = useMemo(() => {
    return initialFilters.find(({ columnName }) => columnName === 'cycle__id')
  }, [initialFilters])

  useEffect(() => {
    // We need this so that we can set the correct cycle if user decides to change cycle length
    // but we also don't want to reset cycles on "go back" to the performance page
    const directedFromCycleSelect = location?.state && location.state.selectedCycle

    if (initialFilterCycle && directedFromCycleSelect) {
      table.onFilterChange(initialFilterCycle)
    }
  }, [initialFilterCycle])

  const canManageGoals =
    entity.data.field_options.permissions?.includes(PermissionTypes.CanManageGoals) ||
    false

  const canSeeSettings =
    (permissions.includes(PermissionTypes.ViewKPITemplate) &&
      performanceSettings?.enable_kpi_templates) ||
    (permissions.includes(PermissionTypes.ViewPerformancePreferences) &&
      (entity.type === EntityTypes.company || entity.type === EntityTypes.companyV2))

  const canImportGoals = permissions.includes(PermissionTypes.KPIAdministratorPermissions)

  const isOnboarding = isOnboardingPath()
  const isPerformanceRoute = useRouteMatch(ROUTES.PERFORMANCE.ANY)

  useEffect(() => {
    form.reset({
      ...form.values,
      goals: table.data,
    })
  }, [table.data])

  useEffect(() => {
    // fix for when window refocuses we set the correct values to the form
    if (form && !isEqual(form.initialValues.goals, table.data) && manageMode) {
      form.reset({
        ...form.values,
        goals: table.data,
      })
    }
  }, [form.initialValues.goals])

  useEffect(() => {
    onManageModeChange?.(manageMode)
    // exclude drafts and archived from edit weigths in case current filters include them
    if (manageMode) {
      const currentFilters = table.filterBy.filter(
        ({ columnName }) => columnName !== 'approval_status',
      )
      table.onFilterChange([
        ...currentFilters,
        {
          columnName: 'approval_status',
          filters: [
            { id: Statuses.pending, name: Statuses.pending },
            { id: Statuses.approved, name: Statuses.approved },
            { id: Statuses.requires_changes, name: Statuses.requires_changes },
          ],
        },
      ])
    }
  }, [manageMode])

  const goalsIdsToApprove = table.data
    ?.filter(
      ({ approval_status, status }) =>
        ![Statuses.draft, Statuses.archived].includes(status.id) &&
        approval_status.id !== ApprovalStatuses.Approved,
    )
    .map(({ id }) => ({ id }))

  const getGoalUrl = (goal: GoalsInterface) => {
    const route =
      goal.status.id === Statuses.draft
        ? ROUTES.FORMS.GOAL.EDIT
        : ROUTES.FORMS.GOAL.PREVIEW
    const onboardingRoute =
      goal.status.id === Statuses.draft
        ? ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.GOAL.EDIT
        : ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.GOAL.PREVIEW

    return {
      ...getLocationDescriptor(
        pathToUrl(isOnboarding ? onboardingRoute : route, { id: goal.id }),
      ),
      search: new URLSearchParams(groupFiltersIntoURLQuery(table.filterBy)).toString(),
    }
  }

  const isSingleEntitySelected = table.filterBy.some(
    filter => filter.columnName === 'object_id' && filter.filters.length === 1,
  )
  const isCompany = table.filterBy.some(
    filter =>
      (filter.columnName === 'is_company' &&
        ['True', 'true'].includes(String(filter.filters[0].id))) ||
      (filter.columnName === 'is_top_level' &&
        ['True', 'true'].includes(String(filter.filters[0].id))),
  )

  const row = useMemo<
    EditableRowInterface<GoalsInterface> | RowInterface<GoalsInterface>
  >(() => {
    const contentId = table.filterBy.find(f => f.columnName === 'content_type_id')
      ?.filters[0]?.id
    let orgUnitFilterType: 'department' | 'team' | undefined
    if (contentId) {
      const model = contentTypeData?.find(({ id }) => id === Number(contentId))?.model
      switch (model) {
        case 'department':
          orgUnitFilterType = 'department'
          break
        case 'teams':
          orgUnitFilterType = 'team'
          break
        default:
          break
      }
    }

    let canChangeStatus = false

    if (isCompany) {
      canChangeStatus = canManageGoals
      // check if not on goals tab with selector
    } else if (![EntityTypes.company, EntityTypes.companyV2].includes(entity.type)) {
      canChangeStatus = canManageGoals
    }

    return {
      highlight: goal =>
        [ApprovalStatuses.Draft, ApprovalStatuses.Archived].includes(
          goal.approval_status.id,
        )
          ? Token.color.greyTone2
          : rowHighlight(goal.approval_status.id),
      cells: [
        {
          ...goalsNameColumn(getGoalUrl),
          width: 300,
        },
        entity.type === EntityTypes.company || entity.type === EntityTypes.companyV2
          ? {
              ...goalsOrgUnitColumn({
                companyName: entity.data.name,
                type: orgUnitFilterType,
              }),
              width: 160,
            }
          : null,
        {
          ...(manageMode ? goalsInputWeightColumn : goalsWeightColumn),
          width: 92,
        },
        { ...goalsProgressColumn, width: 160 },
        ...(!isMultipleGoalsTargetsEnabled ? singleTargetAdditionalColumns : []),
        {
          ...goalsOwnerColumn,
          width: 150,
        },
        {
          ...goalsChangeStatusColumn(
            (status, goalId) =>
              table.updateRows(
                r => r.id === goalId,
                r => ({ ...r, status }),
              ),
            canChangeStatus,
          ),
          width: 100,
        },
        {
          ...goalsApprovalStatusColumn,
          width: 50,
        },
      ].filter(Boolean),
    }
  }, [manageMode, table.data, weightMode, selectedTarget, contentTypeData])

  const entityTypePerformanceGraphPath: SupportedEntityGraphPath | undefined = {
    [EntityTypes.company]: 'company' as const,
    [EntityTypes.companyV2]: 'company' as const,
    [EntityTypes.department]: 'departments' as const,
    [EntityTypes.team]: 'teams' as const,
    [EntityTypes.teams]: 'teams' as const,
    [EntityTypes.employee]: 'employees' as const,
    [EntityTypes.employees]: 'employees' as const,
    [EntityTypes.function]: undefined,
    [EntityTypes.role]: undefined,
    [EntityTypes.specialisation]: undefined,
  }[entity.type]

  let warnAboutMandatoryGoal = false

  if (!(isPerformanceRoute || isOnboarding)) {
    warnAboutMandatoryGoal = isNumber(table.stats?.mandatory_progress)
      ? Number(table.stats?.mandatory_progress) < 1
      : false
  }

  return (
    <>
      <Table.Widget>
        <Table.Widget.Info>
          <GoalCycleFilter
            allowEmployeeCycles
            onFilterChange={table.onFilterChange}
            columnName="cycle__id"
            setShouldHideAddButton={setIsAddButtonHidden}
            setCycleCategory={setCycleCategory}
            selector={selectorKeys.review_cycles}
            filter={table.filterBy}
          />
          <OverallProgress
            showBarGraph={isSingleEntitySelected}
            warnAboutMandatoryGoal={warnAboutMandatoryGoal}
            value={table.stats?.overall_progress}
            fetchData={
              entityTypePerformanceGraphPath
                ? fetchEntityGoalsGraph(entityTypePerformanceGraphPath)
                : undefined
            }
            id={entity?.data.id}
          />
        </Table.Widget.Info>
        <Table.Widget.Actions>
          {table.loading ? (
            <MoreBarSkeleton />
          ) : (
            <Table.Widget.MoreBar>
              <BulkGoalApproveAction
                goals={goalsIdsToApprove}
                onApproved={table.refresh}
                title={
                  goalsIdsToApprove.length === table.data.length
                    ? 'Approve all goals'
                    : undefined
                }
              />
              {!isAddButtonHidden && <AddGoalAction preselectedType={preselectedType} />}
              <CascadeGoalAction />
              {table.data.length > 0 &&
                canManageGoals &&
                (isSingleEntitySelected || isCompany) && (
                  <MoreBar.Action
                    useIcon={manageMode ? 'SwitchOn' : 'SwitchOff'}
                    onClick={toggleManageMode}
                  >
                    Edit weights
                  </MoreBar.Action>
                )}
              {manageMode && (
                <MoreBar.Action useIcon="AutoExchange" onClick={autoDistributeWeights}>
                  Auto-distribute weights
                </MoreBar.Action>
              )}
              {canImportGoals && (
                <MoreBar.Action
                  useIcon="Upload"
                  onClick={() =>
                    navigateTo(
                      pathToUrl(
                        isOnboarding
                          ? ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.UPLOAD.IMPORT_TYPE
                          : ROUTES.FORMS.IMPORT_DATA.GOALS.IMPORT_TYPE,
                        undefined,
                      ),
                    )
                  }
                >
                  Import data
                </MoreBar.Action>
              )}
              {canSeeSettings && <GoalsSettingsButton />}
            </Table.Widget.MoreBar>
          )}
        </Table.Widget.Actions>
        {[EntityTypes.company, EntityTypes.companyV2].includes(entity.type) && (
          <Table.Widget.Filters>
            <GoalLevelSelector
              cycleFilter={table.filterBy.find(f => f.columnName === 'cycle__id')}
              onFilterChange={table.onFilterChange}
              setPreselectedType={setPreselectedType}
            />
          </Table.Widget.Filters>
        )}
        <Table.Widget.Table>
          {manageMode ? (
            <>
              <LapeEditableTable<GoalsInterface>
                dataFieldName="goals"
                name={TableNames.Goals}
                dataType="Goal"
                disableFilters={manageMode}
                {...table}
                initialData={table.data}
                row={row}
                useWindowScroll
                replaceOnInitialDataChange
              />
              {manageMode && (
                <Flex height={20}>
                  {!table.loading && table.data.length ? validationMessage : null}
                </Flex>
              )}
            </>
          ) : (
            <AdjustableTable<GoalsInterface, GoalsStats>
              name={TableNames.Goals}
              emptyState={
                <EmptyGoalTable
                  preselectedType={preselectedType}
                  withAction={!isAddButtonHidden}
                />
              }
              dataType="Goal"
              {...table}
              row={row as RowInterface<GoalsInterface>}
              expandableType="chevron"
              useFetchedChildren
              useWindowScroll
            />
          )}
        </Table.Widget.Table>
      </Table.Widget>
      {!!manageMode && (
        <PageActions height={52} my="s-4" maxWidth="100%">
          {form.dirty && (
            <NewSaveButtonWithPopup
              disabled={!!validationMessage}
              onClick={() => handleSubmit()}
              successText="Goal weights have been updated"
            >
              Submit
            </NewSaveButtonWithPopup>
          )}
        </PageActions>
      )}
      <GoalTargetSidebarForm
        target={selectedTarget}
        onClose={() => setSelectedTarget(undefined)}
        onAfterSubmit={() => {
          table.refresh()
          setSelectedTarget(undefined)
        }}
      />
      {confirmationDialog}
    </>
  )
}

export const GoalsTableFormWidget = React.memo(
  ({
    initialFilters,
    onManageModeChange,
  }: {
    initialFilters: FilterByInterface[]
    onManageModeChange?: (enabled: boolean) => void
  }) => {
    const { entity } = useOrgEntity()

    const initialValues = useMemo(() => {
      return entity && 'goal_weight_mode' in entity.data && entity.data.goal_weight_mode
        ? {
            goals: [],
            goal_weight_mode: entity.data.goal_weight_mode,
          }
        : {
            goals: [],
            goal_weight_mode: { id: 'automatic' },
          }
    }, [entity?.type])

    return entity ? (
      <LapeForm
        disableValidation
        onSubmit={() => Promise.resolve({})}
        initialValues={initialValues}
      >
        <GoalsTableWidget
          initialFilters={initialFilters}
          entity={entity}
          onManageModeChange={onManageModeChange}
        />
      </LapeForm>
    ) : null
  },
)
