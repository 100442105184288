import React from 'react'
import { Avatar, BottomSheet, Header, InputGroup } from '@revolut/ui-kit'
import { FormattedMessage } from 'react-intl'
import { UpdateTypes } from '@src/interfaces/kpis'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { integrationPopupContentMap } from '@src/pages/Forms/GoalForm/Form/GoalMetricForm/integrationPopupContentMap'
import { updateTypeOptionMap } from '@src/pages/Forms/GoalForm/Form/GoalMetricForm/updateTypeOptionMap'
import {
  askJiraWorkspacePermissions,
  askLookerWorkspacePermissions,
} from '@src/api/officeSuiteProvider'

interface Props {
  isOpen: boolean
  onClose: VoidFunction
  type: Extract<UpdateTypes, UpdateTypes.roadmap | UpdateTypes.looker>
}

export const IntegrationEnablePopup = ({ isOpen, onClose, type }: Props) => {
  const { name, icon, image, iconBackground } = updateTypeOptionMap[type]
  const handleConnection = async () => {
    if (type === UpdateTypes.roadmap) {
      await askJiraWorkspacePermissions()
    }
    if (type === UpdateTypes.looker) {
      await askLookerWorkspacePermissions()
    }
    onClose()
  }
  return (
    <BottomSheet open={isOpen} onClose={onClose}>
      <Header>
        <Header.Title>
          <FormattedMessage
            values={{ name }}
            defaultMessage="Your {name} account"
            id="performance.goalForm.connection.popup.title"
          />
        </Header.Title>
        <Header.Actions>
          <Avatar image={image} useIcon={icon} bg={iconBackground} />
        </Header.Actions>
      </Header>
      <InputGroup>
        <LapeNewInput
          name="server"
          label={integrationPopupContentMap[type].label}
          required
        />
        {integrationPopupContentMap[type].description}
      </InputGroup>
      <BottomSheet.Actions>
        <NewSaveButtonWithPopup
          noPopup
          useValidator
          onAfterSubmit={handleConnection}
          isExistingData
          hideWhenNoChanges={false}
        >
          <FormattedMessage
            values={{ name: updateTypeOptionMap[type].name }}
            defaultMessage="Connect to {name}"
            id="performance.goalForm.connection.popup.cta"
          />
        </NewSaveButtonWithPopup>
      </BottomSheet.Actions>
    </BottomSheet>
  )
}
