import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { KpiGoals, KpiInterface } from '@src/interfaces/kpis'
import React from 'react'

export const InitTargetValues = () => {
  const { values } = useLapeContext<KpiInterface>()
  const isInMaintainMode =
    values.targets?.[0]?.kpi_goal &&
    [KpiGoals.keep_target, KpiGoals.keep_target_down, KpiGoals.keep_target_up].includes(
      values.targets?.[0]?.kpi_goal,
    )
  return (
    <>
      <LapeNewInput
        type="money"
        name="targets.0.initial_value"
        required
        label={isInMaintainMode ? 'Lower limit' : 'Initial value'}
        onAfterChange={value => {
          const newValue = value !== '' ? Number(value) : null
          if (values.targets[0]) {
            values.targets[0].initial_value = newValue
          } else {
            values.targets.push({
              initial_value: newValue,
              target: null,
              kpi_goal: KpiGoals.increase,
              parent_kpi: null,
            })
          }
        }}
      />

      <LapeNewInput
        type="money"
        name="targets.0.target"
        required
        label={isInMaintainMode ? 'Upper limit' : 'Target value'}
        onAfterChange={value => {
          const newValue = value !== '' ? Number(value) : null
          if (values.targets[0]) {
            values.targets[0].target = newValue
          } else {
            values.targets.push({
              initial_value: null,
              target: newValue,
              kpi_goal: KpiGoals.increase,
              parent_kpi: null,
            })
          }
        }}
      />
    </>
  )
}
