import React, { useEffect, useState } from 'react'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { KpiInterface } from '@src/interfaces/kpis'
import { useKPIColumns } from '@src/api/kpis'
import { isArray } from 'lodash'
import { lookerValidator } from '../GoalMetricForm/helpers'

export const LookerTargetColumn = () => {
  const { values, initialValues } = useLapeContext<KpiInterface>()
  const [isLookUrlValid, setIsLookUrlValid] = useState(false)
  const { mutateAsync: fetchOptions, data, isLoading } = useKPIColumns()

  const options = isArray(data?.data)
    ? data?.data.map(opt => ({
        id: opt,
        label: opt,
        value: { id: opt, name: opt },
      })) || []
    : []

  useEffect(() => {
    if (values.look_url !== initialValues.look_url) {
      // clear column if look url changed
      values.counted_sql_column = undefined
    }
    if (values.look_url) {
      lookerValidator
        .parseAsync(values.look_url)
        .then(async result => {
          if (result) {
            setIsLookUrlValid(!!result)
            await fetchOptions({ query_db: 'looker', look_url: values.look_url })
          }
        })
        .catch(() => {
          setIsLookUrlValid(false)
        })
    } else {
      setIsLookUrlValid(false)
    }
  }, [values.look_url])

  if (!isLookUrlValid) {
    return null
  }
  const errorMessage = data?.data && 'error' in data.data ? data?.data.error : undefined

  return (
    <LapeRadioSelectInput
      loading={isLoading}
      options={options}
      value={
        values.counted_sql_column
          ? { id: values.counted_sql_column, name: values.counted_sql_column }
          : undefined
      }
      onChange={value => {
        if (value?.id) {
          values.counted_sql_column = value.id
        }
      }}
      message={errorMessage}
      hasError={!!errorMessage}
      name="counted_sql_column"
      label="Target column"
    />
  )
}
