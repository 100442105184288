import {
  Avatar,
  Widget,
  Flex,
  Icon,
  Placeholder,
  Token,
  BottomSheet,
  Header,
  InputGroup,
} from '@revolut/ui-kit'
import { UpdateTypes } from '@src/interfaces/kpis'
import React, { useState } from 'react'
import { updateTypeOptionMap } from './updateTypeOptionMap'
import { FormattedMessage } from 'react-intl'
import { integrationPopupContentMap } from './integrationPopupContentMap'
import {
  askJiraWorkspacePermissions,
  askLookerWorkspacePermissions,
} from '@src/api/officeSuiteProvider'
import Form from '@src/features/Form/Form'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import InputsSkeleton from '@src/components/Skeletons/InputsSkeleton'
import { integrationApiFormMap, serverNameValidators } from './constants'
import { lookerIntegrationPreferences } from '@src/api/integrations'
import { newTabTo } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

interface Props {
  type: UpdateTypes
}

export const NonIntegratedType = ({ type }: Props) => {
  const { name, placeholderDescription, icon, image, iconBackground } =
    updateTypeOptionMap[type]
  const [isPopupOpen, setIsPopupOpen] = useState(false)

  const closePopup = () => {
    setIsPopupOpen(false)
  }

  const handleConnect = async () => {
    if (type === UpdateTypes.looker) {
      const resp = await lookerIntegrationPreferences.get({ id: '1' })
      if (resp.data?.method?.id === 'basic') {
        // for the existing tennants we need to support connecting to looker without oauth
        // where to manage connections in analytics tab
        newTabTo(ROUTES.SETTINGS.ANALYTICS)
        return
      }
    }
    setIsPopupOpen(true)
  }

  return (
    <>
      <Widget>
        <Placeholder>
          <Flex alignItems="center" gap="s-8" py="s-24">
            <Avatar
              useIcon="LogoRevolut"
              color={Token.color.white}
              bg={Token.color.blue}
              variant="square"
            />
            <Icon name="Link" />
            <Avatar image={image} useIcon={icon} bg={iconBackground} />
          </Flex>
          <Placeholder.Title>
            <FormattedMessage
              values={{ name }}
              defaultMessage="Connect {name} to get started"
              id="performance.goalForm.connection.placeholder.title"
            />
          </Placeholder.Title>
          <Placeholder.Description>{placeholderDescription}</Placeholder.Description>
          <Placeholder.Action useIcon="Link" onClick={handleConnect}>
            <FormattedMessage
              values={{ name }}
              defaultMessage="Connect {name}"
              id="performance.goalForm.connection.placeholder.cta"
            />
          </Placeholder.Action>
        </Placeholder>
      </Widget>
      <BottomSheet open={isPopupOpen} onClose={closePopup}>
        <Header>
          <Header.Title>
            <FormattedMessage
              values={{ name }}
              defaultMessage="Your {name} account"
              id="performance.goalForm.connection.popup.title"
            />
          </Header.Title>
          <Header.Actions>
            <Avatar image={image} useIcon={icon} bg={iconBackground} />
          </Header.Actions>
        </Header>
        <UpdateForm type={type} closePopup={closePopup} />
      </BottomSheet>
    </>
  )
}

const UpdateForm = ({ type, closePopup }: Props & { closePopup: VoidFunction }) => {
  const handleConnection = async () => {
    if (type === UpdateTypes.roadmap) {
      await askJiraWorkspacePermissions()
    }
    if (type === UpdateTypes.looker) {
      await askLookerWorkspacePermissions()
    }
    closePopup()
  }
  return (
    <Form
      loadingState={<InputsSkeleton />}
      api={integrationApiFormMap[type]}
      validator={serverNameValidators[type]}
      forceParams={{ id: '1' }}
    >
      <InputGroup>
        <LapeNewInput
          name="server"
          label={integrationPopupContentMap[type].label}
          required
        />
        {integrationPopupContentMap[type].description}
      </InputGroup>
      <BottomSheet.Actions>
        <NewSaveButtonWithPopup
          noPopup
          useValidator
          onAfterSubmit={handleConnection}
          isExistingData
          hideWhenNoChanges={false}
        >
          <FormattedMessage
            values={{ name: updateTypeOptionMap[type].name }}
            defaultMessage="Connect to {name}"
            id="performance.goalForm.connection.popup.cta"
          />
        </NewSaveButtonWithPopup>
      </BottomSheet.Actions>
    </Form>
  )
}
