import { Highlights, DataPoint, DataPointSkeleton } from '@revolut/ui-kit'
import React, { forwardRef, PropsWithChildren } from 'react'
import { UseFetchResult } from '@src/interfaces'
import { pathToUrl } from '@src/utils/router'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { useRouteMatch } from 'react-router-dom'

interface StatNavigationProps<T> {
  api: () => UseFetchResult<T>
  config: {
    key: keyof T
    label: string
    to: string
  }[]
}

const DataPointLink = forwardRef<HTMLAnchorElement, PropsWithChildren<{ to: string }>>(
  ({ to, ...rest }, ref) => (
    <DataPoint
      ref={ref}
      aria-selected={Boolean(useRouteMatch({ path: to, exact: true }))}
      use={InternalLink}
      to={to}
      {...rest}
    />
  ),
)

export const StatNavigation = <T,>({ api, config }: StatNavigationProps<T>) => {
  const { data, isLoading } = api()

  return (
    <Highlights variant="tabs">
      {config.map(entry => (
        <DataPointLink to={pathToUrl(entry.to)} key={entry.label}>
          {isLoading ? (
            <DataPointSkeleton.Value />
          ) : (
            <DataPoint.Value>{data?.[entry.key]}</DataPoint.Value>
          )}
          <DataPoint.Label>{entry.label}</DataPoint.Label>
        </DataPointLink>
      ))}
    </Highlights>
  )
}
